import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import messages from '@components/Flows/DeleteFlowModal/messages';
const DeleteFlowModal = ({ handleToggleDeleteFlowModal, openDeleteFlowModal, deleteSelectedFlow, }) => {
    const handleCloseModal = () => {
        handleToggleDeleteFlowModal();
    };
    return (_jsx(Modal, { size: "sm", show: openDeleteFlowModal, onCancel: handleCloseModal, children: _jsxs(_Fragment, { children: [_jsx("div", { className: "flex items-start", children: _jsxs("div", { className: "text-left", children: [_jsx("p", { className: "text-xl font-semibold leading-6 text-gray-900", id: "modal-title", children: _jsx(FormattedMessage, Object.assign({}, messages.modal_title)) }), _jsx("div", { className: "mt-4", children: _jsx("p", { className: "text-sm font-normal text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.modal_desciption)) }) })] }) }), _jsxs("div", { className: "flex flex-row-reverse mt-6", children: [_jsx(Button, { variant: "destructive", onClick: deleteSelectedFlow, children: _jsx(FormattedMessage, Object.assign({}, messages.delete_button)) }), _jsx("div", { className: "mr-3", children: _jsx(Button, { variant: "outline", onClick: handleCloseModal, children: _jsx(FormattedMessage, Object.assign({}, messages.back_button)) }) })] })] }) }));
};
export default DeleteFlowModal;
