import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import Tabs from '@components/Base/Tabs';
import ClipBoardIcon from 'app/images/clipboard.svg';
import messages from './messages';
import RequestsList from '../RequestsList';
const SessionsList = ({ sessionData }) => {
    const [selectedTab, setSelectedTab] = useState('sessions');
    const handleTabSelect = (value) => {
        setSelectedTab(value);
    };
    return (_jsx("div", { className: "flex w-full px-8 py-5", children: sessionData.length > 0 ? (_jsxs("div", { className: "flex flex-col w-full", children: [_jsx("div", { className: '"flex', children: _jsx(Tabs, { tabData: [
                            {
                                label: _jsx(FormattedMessage, Object.assign({}, messages.all_sessions)),
                                value: 'sessions',
                            },
                            {
                                label: _jsx(FormattedMessage, Object.assign({}, messages.requests)),
                                value: 'requests',
                            },
                        ], selectedValue: selectedTab, onSelect: handleTabSelect }) }), selectedTab == 'sessions' ? (_jsx(_Fragment, {})) : (_jsx(RequestsList, { requestsData: [], "data-testid": "requests-list" }))] })) : (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: messages.no_session, descriptionMessage: messages.no_session_description, descriptionWidth: "w-48" })) }));
};
export default SessionsList;
