import { ROLE } from '@containers/app/types';
import { DUPLICATE_FLOW_ERROR, FLOW_DELETION_ERROR } from '@utils/data/enums';
export const options = [
    { value: 'addNewProject', label: 'Add a new project' },
    {
        label: 'Switch to project',
        options: [
            {
                value: 'project1',
                label: 'Global Tech Innovators Exam - TSF',
                details: { id: '#878902', location: 'India' },
                disabled: false,
            },
            {
                value: 'project2',
                label: 'MBA Entrance Series - North American University',
                details: { id: '#878903', location: 'India' },
                disabled: false,
            },
            {
                value: 'project3',
                label: 'European Art History Assessment',
                details: { id: '#878904', location: 'India' },
                disabled: false,
            },
            {
                value: 'project4',
                label: 'Asia-Pacific Leadership Program Test - One European Art History, socio economic culture in middle ages',
                details: { id: '#878905', location: 'India' },
                disabled: false,
            },
        ],
    },
    {
        label: 'DEACTIVATED',
        options: [
            {
                value: 'project4',
                label: 'Asia-Pacific Leadership Program Test',
                details: { id: '#878905', location: 'India' },
                disabled: true,
            },
            {
                value: 'project5',
                label: 'Asia-Pacific Leadership Program Test-1',
                details: { id: '#878906', location: 'India' },
                disabled: true,
            },
        ],
    },
];
export const currentProject = {
    id: '1',
    name: 'Test Project',
    region: 'us-west-2',
    flows: [
        {
            id: '1',
            name: 'Test Flow',
        },
    ],
    token: '0aa11586-9771-4988-8112-3ccce32a46fe',
};
export const currentProjectWithOutFlows = {
    id: '1',
    name: 'Test Project',
    region: 'us-west-2',
    flows: [],
    token: '0aa11586-9771-4988-8112-3ccce32a46fe',
};
export const currentProjectsInitialState = {
    id: '',
    name: '',
    region: '',
    description: '',
    flows: [],
    token: '',
};
export const newProjectsInitialState = {
    id: '',
    name: '',
    region: '',
    description: '',
    flows: [],
    token: '',
};
export const flowInitialState = {
    isLoading: false,
    error: '',
    uuid: '',
};
export const flowSuccessState = {
    uuid: '123-123-123',
    isLoading: false,
    error: '',
};
export const flowErrorState = {
    uuid: '',
    isLoading: false,
    error: 'error creating flow',
};
export const flowDuplicateErrorState = {
    uuid: '',
    isLoading: false,
    error: DUPLICATE_FLOW_ERROR,
};
export const deleteFlowErrorState = {
    uuid: '',
    isLoading: false,
    error: FLOW_DELETION_ERROR,
};
export const mockTemplateList = [
    {
        id: '1',
        name: 'Template 1',
        project_id: '10',
        is_template: true,
    },
    {
        id: '2',
        name: 'Template 2',
        is_template: true,
        project_id: '10',
    },
];
export const duplicateFlowErrorState = {
    uuid: '',
    isLoading: false,
    error: 'Uniqueness violation. duplicate key value violates unique constraint "idx_flow_project_id_name"',
};
export const mockProjects = [
    {
        id: '1',
        name: 'TEST PROJECT',
        region: 'AUSTRALIA',
        tags: [
            {
                id: 1,
                key: 'env',
                value: 'staging',
            },
        ],
        flows: [
            {
                id: '1',
                name: 'FLOW 1',
            },
            {
                id: '2',
                name: 'FLOW 2',
            },
        ],
        token: '0aa11586-9771-4988-8112-3ccce32a46fe',
    },
    {
        id: '2',
        name: 'TEST PROJECT 2',
        region: 'EUROPE',
        tags: [
            {
                id: 1,
                key: 'isProd',
                value: 'false',
            },
        ],
        flows: [],
        token: '0aa11586-9771-4988-8112-3ccce32a46fe',
    },
];
export const mockBlackListApps = {
    mac: [
        'Anydesk',
        'Teamviewer',
        'Firefox',
        'Chrome',
        'Opera',
        'Safari',
        'Teams',
        'Slack',
        'Skype',
        'Facetime',
        'Brave',
        'Zoom',
    ],
    windows: [
        'Anydesk',
        'Teamviewer',
        'Firefox',
        'Chrome',
        'Opera',
        'Safari',
        'Edge',
        'Webex',
        'Riot',
        'Seamonkey',
        'Teams',
        'G2M',
        'Zoom',
        'Element',
        'Slack',
        'Camtasia',
        'Camrecorder',
        'Camplay',
        'Discord',
        'Skype',
        'Join Me',
        'RPC Suite',
        'Remote',
        'Beam your screen',
        'Aero',
        'VNC',
        'Gotomeeting',
        'Ammy admin.',
    ],
};
export const mockUser = {
    currentRole: ROLE.TENANT_ADMIN,
    accountName: '',
    isLoggedIn: true,
    email: 'john.doe@example.com',
    uid: 'rSTR5v031daNRLHOoXaloDp7sK00',
    name: 'John Doe',
    username: 'john.doe@example.com',
    id: 1,
    tenant: {
        id: 69,
        name: 'Example',
        subdomain: 'Example',
    },
    roles: [],
    metadata: {
        createdAt: '1716873227909',
        creationTime: 'Tue, 28 May 2024 05:13:47 GMT',
        lastLoginAt: '1721818882951',
        lastSignInTime: 'Wed, 24 Jul 2024 11:01:22 GMT',
    },
    url: '',
};
