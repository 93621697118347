import { defineMessages } from 'react-intl';
export const scope = 'app.components.sessions.requests_list';
export default defineMessages({
    no_requests: {
        id: `${scope}.no_requests`,
        defaultMessage: 'No Review Requests Yet',
    },
    no_requests_description: {
        id: `${scope}.no_requests_description`,
        defaultMessage: 'There are no review requests at the moment.',
    },
});
