import { defineMessages } from 'react-intl';
export const scope = 'app.components.sessions.sessions_list';
export default defineMessages({
    all_sessions: {
        id: `${scope}.all_sessions`,
        defaultMessage: 'All Sessions',
    },
    requests: {
        id: `${scope}.requests`,
        defaultMessage: 'Requests',
    },
    no_session: {
        id: `${scope}.no_session`,
        defaultMessage: 'No Sessions Yet',
    },
    no_session_description: {
        id: `${scope}.no_session_description`,
        defaultMessage: 'There are no sessions to view at the moment.',
    },
});
