import { jsx as _jsx } from "react/jsx-runtime";
import { signoutAction, updateUserAction, uploadProfilePhotoAction, } from '@containers/app/slice';
import { useAppDispatch } from '@utils/hooks';
const withUserActions = (WrappedComponent) => {
    const ComponentWithUserActions = (props) => {
        const dispatch = useAppDispatch();
        const actions = {
            onSignout: () => dispatch(signoutAction()),
            onUpdateProfilePhoto: (payload) => dispatch(uploadProfilePhotoAction(payload)),
            onUpdateUserAction: (payload) => dispatch(updateUserAction(payload)),
        };
        return _jsx(WrappedComponent, Object.assign({}, props, actions));
    };
    return ComponentWithUserActions;
};
export default withUserActions;
