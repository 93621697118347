export const CONFIG_TYPE = {
    CUSTOM_BRANDING: 'CUSTOM_BRANDING',
    OFFBOARDING: 'OFFBOARDING',
    SETTING_STAKES: 'SETTING_STAKES',
    ATTENDEE_ONBOARDING: 'ATTENDEE_ONBOARDING',
    VERSION: 'VERSION',
};
export const STATUS = {
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
    ARCHIVED: 'ARCHIVED',
};
export const ADD_FLOW_MODAL_TAB = {
    NEW_FLOW: 0,
    TEMPLATE: 1,
    DUPLICATE_FLOW: 2,
};
export const FEED_TYPE = {
    DISABLE: 'DISABLE',
    STREAM: 'STREAM',
    STREAM_AND_RECORD: 'STREAM_AND_RECORD',
};
export const VERIFICATION_TYPE = {
    DISABLE: 'DISABLE',
    CAPTURE: 'CAPTURE',
    VERIFY: 'VERIFY',
};
export const PHOTO_ID_VERIFICATION_TYPE = {
    EXTERNAL_SOURCE: 'EXTERNAL_SOURCE',
    FACE: 'FACE',
    FACE_AND_NAME: 'FACE_AND_NAME',
};
export const TOAST_VARIANT = {
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    INFO: 'info',
};
export const TOAST_STATE = {
    FLOW_CONFIG_CHANGES_SAVED: 'FLOW_CONFIG_CHANGES_SAVED',
    FLOW_CONFIG_CHANGES_FAILED: 'FLOW_CONFIG_CHANGES_FAILED',
    UPDATE_FLOW_NAME_INITIATED: 'UPDATE_FLOW_NAME_INITIATED',
    UPDATE_FLOW_NAME_SUCCESS: 'UPDATE_FLOW_NAME',
    UPDATE_FLOW_NAME_FAILED: 'UPDATE_FLOW_NAME_FAILED',
    PUBLISH_FLOW: 'PUBLISH_FLOW',
    PROJECT_DETAILS_SAVED: 'PROJECT_DETAILS_SAVED',
    PROJECT_DETAILS_SAVE_FAILED: 'PROJECT_DETAILS_SAVE_FAILED',
};
export const MAX_45_CHARS = 45;
export const FLOW_DELETION_ERROR = 'FLOW_DELETION_ERROR';
export const DUPLICATE_FLOW_ERROR = 'Uniqueness violation. duplicate key value violates unique constraint "idx_flow_project_id_name"';
export const VERSION_LOG_KEY_VALUE_MAPTING = {
    ENABLE: 'enabled',
    DISABLE: 'disabled',
    FACE: 'face_type',
    NOT_AVAILABLE: 'N/A',
    VERSION: 'Version',
    BOOLEAN: 'boolean',
    SCREEN_SHARE_RECORDING: 'screen_share_recording',
    PHOTO_ID_VERIFICATION: 'photo_id_verification',
    FACE_CAPTURE_VERIFICATION: 'face_capture_verification',
    SECONDARY_CAMERA_RECORDING: 'secondary_camera_recording',
};
export const keyGroups = {
    cameraFeeds: {
        screenFeedKey: 'screen_feed',
        secondaryCameraFeedKey: 'secondary_camera_feed',
    },
    photoVerification: {
        photoIdKey: 'photo_id',
        faceCaptureKey: 'face',
    },
};
export const KeysToAddNewKeyValues = [
    keyGroups.photoVerification.photoIdKey,
    keyGroups.photoVerification.faceCaptureKey,
    keyGroups.cameraFeeds.screenFeedKey,
    keyGroups.cameraFeeds.secondaryCameraFeedKey,
];
export const LOCAL_STORAGE_KEYS = {
    LANGUAGE: 'language',
    TIMEZONE: 'timezone',
};
