var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@utils/classNames';
const ToggleButton = (_a) => {
    var { checked, disabled, name, onClick, focusVisible } = _a, rest = __rest(_a, ["checked", "disabled", "name", "onClick", "focusVisible"]);
    const isChecked = checked;
    const handleClick = () => {
        const newChecked = !isChecked;
        if (typeof onClick === 'function') {
            onClick(newChecked);
        }
    };
    return (_jsxs("button", Object.assign({ type: "button", disabled: disabled, name: name, onClick: handleClick, className: classNames(isChecked ? 'bg-primary' : 'bg-gray-400', disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer', 'flex items-center relative inline-flex h-4 w-7 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary', focusVisible && !disabled ? 'ring-2 ring-primary ring-offset-2' : ''), role: "switch", "aria-checked": isChecked ? 'true' : 'false' }, rest, { children: [_jsx("span", { className: "sr-only", children: isChecked ? 'Toggle button checked' : 'Toggle button unchecked' }), _jsx("span", { "aria-hidden": "true", className: classNames(isChecked ? 'translate-x-3' : 'translate-x-0', 'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') })] })));
};
export default ToggleButton;
