export const CREATE_FLOW_MUTATION = `
  mutation createFlow($payload: pc_create_flow_input!) {
    pc_create_flow(data: $payload) {
      id
      uuid
      name
    }
  }`;
export const DELETE_FLOW_MUTATION = `
  mutation flow_delete($payload: pc_delete_input!) {
    pc_delete_flow(data: $payload) {
      id
    }
  }`;
export const TEMPLATE_LIST = `
query getTemplateList {
  pc_flow(where: { is_template: { _eq: true } }) {
    id
    project_id
    name
    is_template
  }
}`;
export const GET_FLOW_LIST_DATA = `
query getFlowList($limit: Int, $offset: Int, $searchExpression: [pc_flow_bool_exp!] = {}, $projectId: Int, $conditions: pc_flow_config_bool_exp = {} ) {
    pc_flow(limit: $limit, offset: $offset, order_by: {updated_at: desc, is_default: desc}, where: { _and: [{_or: $searchExpression}, {project_id: {_eq: $projectId}}, {flow_configs: $conditions}]}) {
        id
        is_default
        is_active
        name
        updated_at
        project_id
        uuid
        flow_configs {
            version
            status
        }
        user_by_updated_by {
            name
            updated_at
        }
    }
    pc_flow_aggregate (
      where: {_and: [{_or: $searchExpression}, {project_id: {_eq: $projectId}}, {flow_configs: $conditions}]}
    ) {
        aggregate {
            count
        }
    }  
}`;
